import React from "react"
import { twMerge } from "tailwind-merge"

export type PageTitleProps = {
  title: string
  subtitle?: string
  children?: React.ReactNode
  className?: string
}

export function PageTitle(props: PageTitleProps) {
  const { title, subtitle, children, className } = props

  return (
    <hgroup className={twMerge("flex flex-col gap-2 py-8 px-6", className)}>
      <h1 className="font-medium md:text-4xl text-3xl text-gray-900 font-souvenir">{title}</h1>
      {subtitle && <p className="text-lg text-gray-500">{subtitle}</p>}
      {children}
    </hgroup>
  )
}
