import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import React, { ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import style from "./StorefrontDropdownMenu.module.scss"

export type StorefrontDropdownMenuProps = {
  title: string
  className?: string
  children: ReactNode
}

export function StorefrontDropdownMenu(props: StorefrontDropdownMenuProps) {
  const { title, className, children } = props

  return (
    <Popover className={twMerge([style.base, className])}>
      <PopoverButton className={style.popoverButton}>{title}</PopoverButton>

      <PopoverPanel
        className={style.popoverPanel}
        anchor={{ to: "bottom", padding: "1rem", gap: "0.5rem" }}
        unmount={false}
        portal={false}
      >
        {children}
      </PopoverPanel>
    </Popover>
  )
}
