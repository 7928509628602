import React, { useState, useRef, ChangeEvent, KeyboardEvent } from "react"
import { ArrowRightIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

export type SearchbarProps = {
  onSearch: (value: string) => void
  className?: string
}

export function Searchbar({ className, onSearch }: SearchbarProps) {
  const [searchValue, setSearchValue] = useState("")
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(searchValue)
    }
  }

  const handleClear = () => {
    setSearchValue("")
    inputRef?.current?.focus()
  }

  return (
    <div
      className={twMerge(
        `flex items-center w-full h-[48px] py-2 pl-4 pr-4 border ${isFocused ? "border-blue-600" : "border-gray-300"} rounded-lg`,
        className
      )}
    >
      <MagnifyingGlassIcon
        className="text-gray-400 h-6 w-6 hover:cursor-pointer"
        onClick={() => inputRef?.current?.focus()}
      />
      <input
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={isFocused ? "" : "Search"}
        className="flex-grow bg-inherit placeholder-gray-500 outline-none border-none ring-0 focus:outline-none focus:ring-0"
        ref={inputRef}
      />
      {searchValue && !isFocused && (
        <button type="button" className="text-gray-500 mr-2" onClick={handleClear}>
          <XMarkIcon className="h-6 w-6" />
        </button>
      )}
      {isFocused && (
        <div className="flex items-center text-gray-500">
          <span className="mr-2">Hit 'Enter' to Search</span>
          <ArrowRightIcon className="h-6 w-6" />
        </div>
      )}
    </div>
  )
}
