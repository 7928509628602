import React, { ReactNode } from "react"
import { Bee } from "~/src/components/BeeKit"
import { ColumnDef } from "~/src/components/BeeKit/DataTable"
import { OrderRecipient } from "~/src/types/preferredGift"
import { isNil, isntNil } from "~/src/lib/any"
import { addressLines } from "~/src/lib/address"

export type RecipientTableProps = {
  orderRecipients: OrderRecipient[]
}

export function RecipientTable(props: RecipientTableProps) {
  const { orderRecipients } = props

  const columnDefs: ColumnDef<OrderRecipient>[] = [
    { name: "Recipient", renderCell: "name" },
    { name: "Email", renderCell: "email" },
    { name: "Company", renderCell: "company" },
  ]

  const withAddressColumn = orderRecipients.some((orderRecipient: OrderRecipient) => isntNil(orderRecipient.address))
  if (withAddressColumn) {
    columnDefs.push({ name: "Address", renderCell: addressCell })
  }

  return (
    <div>
      <Bee.DataTable
        data={orderRecipients}
        columnDefs={columnDefs}
        keyFn={(orderRecipient) => orderRecipient.id}
        className="max-h-[500px] overflow-y-scroll text-sm md:flex hidden"
      />
      <div className="flex flex-col md:hidden border rounded-lg border-gray-200 text-sm divide-y divide-gray-200">
        {orderRecipients.map(({ name, email, company, address }, index) => (
          <div key={index} className="text-gray-900 text-sm px-6 py-4">
            <p className="font-medium">{name}</p>
            <p>{email}</p>
            <p>{company}</p>
            {withAddressColumn ? isntNil(address) ? <div>{address.addressCountry}</div> : <p /> : null}
          </div>
        ))}
      </div>
    </div>
  )
}

function addressCell(orderRecipient: OrderRecipient): ReactNode {
  if (isNil(orderRecipient.address)) {
    return ""
  }

  const lines = addressLines(orderRecipient.address)
  return (
    <div className="flex flex-col">
      {lines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </div>
  )
}
