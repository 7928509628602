import React, { FormEvent, useState } from "react"
import type { AxiosResponse } from "axios"
import { ErrorBoundary } from "react-error-boundary"
import type { IChangeEvent } from "@rjsf/core"
import { ErrorSchema } from "@rjsf/utils"
import validator from "@rjsf/validator-ajv8"

import { Form } from "~/src/components/JsonSchemaForm/themes/bee"
import { iClient } from "~/src/lib/appClients"
import { SolutionForm } from "~/src/types/solutionForm"
import customFields from "./fields"

export type CustomTraySolutionFormProps<TFormData> = {
  initialData?: TFormData
  redirectOnSuccess?: string
  solutionForm: SolutionForm
  solutionId: number
  solutionInstanceId?: number
  storeId: number
}

export function CustomTraySolutionForm<TFormData = any>({
  initialData,
  redirectOnSuccess,
  solutionForm,
  solutionId,
  solutionInstanceId,
  storeId,
}: CustomTraySolutionFormProps<TFormData>) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState<ErrorSchema<TFormData> | undefined>()

  const fallbackRender = ({ error }) => (
    <div>
      <h2 className="text-lg font-medium">Something went wrong</h2>
      <pre>{error.message}</pre>
    </div>
  )

  const handleSubmit = async (
    { formData }: IChangeEvent<TFormData, typeof solutionForm.fieldSchema>,
    e: FormEvent<any>
  ) => {
    let response: AxiosResponse

    e.preventDefault()
    setIsSubmitting(true)

    try {
      let url: string
      if (solutionInstanceId == null) {
        url = `/tray_io/solutions/${solutionId}/instances`
      } else {
        url = `/tray_io/solutions/${solutionId}/instances/${solutionInstanceId}`
      }

      response = await iClient.patch(url, { config: formData }, { params: { store_id: storeId } })

      setErrors(undefined)
      window.location = redirectOnSuccess ?? response.headers.location
    } catch (error) {
      setErrors({
        __errors: [error.message],
      })
      console.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <Form
        disabled={isSubmitting}
        extraErrors={errors}
        fields={customFields}
        formData={initialData}
        schema={solutionForm.fieldSchema}
        uiSchema={solutionForm.uiSchema ?? undefined}
        validator={validator}
        onSubmit={handleSubmit}
      />
    </ErrorBoundary>
  )
}
