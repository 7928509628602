// In development, you need to go to chrome://flags/#unsafely-treat-insecure-origin-as-secure
// enable the flag and add http://lvh.me:3000 to the list of exceptions.
// The clipboard does not work on non-https websites. As far as I can tell,
// There is no way to do this for Firefox at this time.
// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard#browser_compatibility
// We may also need to deal with asking for permission in the future
// https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API

import React, { useState } from "react"
import { Bee } from "."
import { twMerge } from "tailwind-merge"

export type CopyTextProps = {
  value: string
  className?: string
}

export function CopyText(props: CopyTextProps) {
  const { value, className } = props
  const [open, setOpen] = useState(false)

  return (
    <div className={twMerge("gap-0 flex", className)}>
      <input
        type="text"
        value={value}
        readOnly={true}
        onFocus={(e) => e.currentTarget.select()}
        className="border border-gray-300 py-2 pr-0 rounded-l-lg w-full cursor-text focus:ring-inset"
      />
      <Bee.Tip open={open}>
        <Bee.TipTrigger>
          <button
            type="button"
            className="border border-gray-300 border-l-0 bg-blueslate-200 rounded-r-lg font-medium text-center px-4 py-2"
            onClick={() => {
              setOpen(true)
              setTimeout(() => setOpen(false), 1000)
              navigator.clipboard?.writeText(String(value))
            }}
          >
            Copy
          </button>
        </Bee.TipTrigger>
        <Bee.TipBubble showArrow>Copied!</Bee.TipBubble>
      </Bee.Tip>
    </div>
  )
}
