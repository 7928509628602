import React, { useState } from "react"
import { FieldProps } from "@rjsf/utils"

import { iname } from "~/src/lib/iname"
import { Bee } from "~/src/components/BeeKit"

type BeforeOrAfterFieldProps = FieldProps & {
  uiSchema: {
    fieldOptions?: Partial<FieldOptions>
  }
}
type BeforeOrAfterFieldValue = {
  before_or_after: string
  num_days: number
}

type FormatSegment = string | { input: "numDays" | "beforeOrAfter" }
type FieldOptions = {
  format: FormatSegment[]
}

const defaultOptions: FieldOptions = {
  format: [{ input: "numDays" }, "days", { input: "beforeOrAfter" }],
}

/*
 * Provides means for entering a number of days and a before/after value. The field value must be an object with keys
 * `before_or_after` and `num_days`.
 *
 * @param {Object} props
 * @param {Object} props.formData - The form data
 * @param {string} props.name - The field name
 * @param {boolean} props.required - Whether the field is required
 * @param {Object} props.schema - The field schema
 * @param {Object} props.uiSchema - The field UI schema
 * @param {Object} props.uiSchema.fieldOptions - The field options object.
 * @param {Object} props.uiSchema.fieldOptions.format - The format of this field. An array of strings and inputs, where
 *   inputs are objects with a single key `input` and a value of "numDays" or "beforeOrAfter".
 * @param {Function} props.onChange - The change handler
 */
export function BeforeOrAfterField({
  formData,
  name,
  required,
  schema: { title },
  uiSchema: { fieldOptions: providedFieldOptions },
  onChange,
}: BeforeOrAfterFieldProps) {
  const label = title ?? name
  const fieldOptions: FieldOptions = { ...defaultOptions, ...providedFieldOptions }
  const [{ before_or_after: beforeOrAfter, num_days: numDays }, setState] = useState<BeforeOrAfterFieldValue>(formData)

  const handleChange = (name: keyof BeforeOrAfterFieldValue, value: any) => {
    setState((prev) => ({ ...prev, [name]: value }))
    onChange({ ...formData, [name]: value })
  }

  return (
    <Bee.Field label={label} optional={!required}>
      <div className="flex flex-row items-center">
        {fieldOptions.format.map((segment, index) => {
          if (typeof segment === "string") {
            return (
              <div className="px-2" key={index}>
                {segment}
              </div>
            )
          } else if (segment.input === "numDays") {
            return (
              <NumDaysInput
                key={"numDays"}
                name={iname(name).numDays._}
                value={numDays}
                onChange={(value) => handleChange("num_days", value)}
              />
            )
          } else if (segment.input === "beforeOrAfter") {
            return (
              <BeforeOrAfterDropdown
                key={"beforeOrAfter"}
                name={iname(name).beforeOrAfter._}
                value={beforeOrAfter}
                onChange={(value) => handleChange("before_or_after", value)}
              />
            )
          } else {
            return null
          }
        })}
      </div>
    </Bee.Field>
  )
}

type NumDaysInputProps = {
  name: string
  value: number
  onChange: (value: number) => void
}

function NumDaysInput({ name, value, onChange }: NumDaysInputProps) {
  return (
    <div className="inline-block">
      <Bee.Input
        className="w-20"
        type="number"
        name={name}
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
      />
    </div>
  )
}

type BeforeOrAfterDropdownProps = {
  name: string
  value: string
  onChange: (value: string) => void
}

const beforeOrAfterOptions: Bee.ComboboxOption[] = ["before", "after"].map((v) => [v, v])

function BeforeOrAfterDropdown({ name, value, onChange }: BeforeOrAfterDropdownProps) {
  return (
    <div className="basis-32">
      <Bee.ComboboxInput
        immediate
        nullable={false}
        name={name}
        options={beforeOrAfterOptions}
        value={value}
        onChange={(option: Bee.ComboboxOption) => onChange(option[0] as string)}
      />
    </div>
  )
}
