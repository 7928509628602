import * as React from "react"
import { Bee } from "~/src/components/BeeKit"
import { twMerge } from "tailwind-merge"

export type TabBarProps = {
  className?: string
  containerClassName?: string
  children: React.ReactNode
}

export const TabBar: React.FC<TabBarProps> = ({ className, containerClassName, children }) => {
  return (
    <Bee.CarouselNav
      className={twMerge("gap-10 grow shrink w-0", className)}
      containerClassName={twMerge("gap-4 border-b border-b-gray-200", containerClassName)}
    >
      {children}
    </Bee.CarouselNav>
  )
}
