import React from "react"
import partyPopper from "~/images/emoji/party_popper.png"
import star from "~/images/emoji/star.png"
import heartEyes from "~/images/emoji/smiling_face_with_heart_eyes.png"
import smilingFace from "~/images/emoji/smiling_face_with_smiling_eyes.png"
import { twMerge } from "tailwind-merge"

export enum ReactionType {
  HEART_EYES = "heart_eyes",
  PARTY_POPPER = "party_popper",
  SMILING_FACE = "smiling_face",
  STAR = "star",
}

export type ReactionProps = {
  reaction?: ReactionType
  className?: string
}

export function Reaction(props: ReactionProps) {
  const { reaction, className } = props
  if (reaction) {
    return <img src={reactionImage(reaction)} className={twMerge("h-6 w-6", className)} />
  }
}

function reactionImage(reaction: ReactionType) {
  return {
    [ReactionType.HEART_EYES]: heartEyes,
    [ReactionType.PARTY_POPPER]: partyPopper,
    [ReactionType.SMILING_FACE]: smilingFace,
    [ReactionType.STAR]: star,
  }[reaction]
}
