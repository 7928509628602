import React from "react"
import { isntNil } from "~/src/lib/any"
import { Format } from "~/src/lib/format"
import { Bee } from "~/src/components/BeeKit"
import { BudgetAmount } from "~/src/types/budget"

export type BudgetDisplayPillProps = {
  preamble?: string
  budget?: BudgetAmount
  currencyUnit?: string
}

export function BudgetDisplayPill(props: BudgetDisplayPillProps) {
  const { preamble, budget, currencyUnit } = props
  return isntNil(budget) ? (
    <Bee.Pill className="bg-indigo-100 text-navy-800 mt-2">
      {preamble ? `${preamble}` : ""}
      {isntNil(currencyUnit) ? Format.customCurrency(budget, currencyUnit) : Format.usd(budget)}
    </Bee.Pill>
  ) : (
    <></>
  )
}
