import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import React, { useEffect } from "react"
import { isNil } from "~/src/lib/any"
import { iClient } from "~/src/lib/appClients"
import { CollectionItem } from "~/src/types/preferredGift"
import { Bee } from "./BeeKit"
import { CollectionItemBatchGrid } from "./CollectionItemGrid"
import { RailsForm } from "./RailsForm"
import { WorkflowContainer } from "./WorkflowContainer"
import { WorkflowHeading } from "./WorkflowHeading"

const PER: number = 36

export type QuickSendEditStoreContentProps = {
  quickSend: {
    id: number
    storeId?: number
  }
  stores: { id: number; name: string }[]
}

export function QuickSendEditStoreContent(props: QuickSendEditStoreContentProps) {
  const { quickSend, stores = [] } = props
  const storeOptions: Bee.ComboboxOption[] = stores.map((store) => [store.id, store.name])
  const [storeId, setStoreId] = React.useState<Bee.ComboboxOption[0]>(quickSend.storeId ?? null)
  const [collectionItems, setCollectionItems] = React.useState<CollectionItem[]>([])
  const [restItems, setRestItems] = React.useState<CollectionItem[]>([])
  const [collectionItemTotalCount, setCollectionItemTotalCount] = React.useState<number>(0)
  const [showAllItems, setShowAllItems] = React.useState<boolean>(false)

  const fetchCollectionItems = async (storeId: Bee.ComboboxOption[0]) => {
    const { data, headers } = await iClient<CollectionItem[]>(`/stores/${storeId}/collection_items?per=${PER}`)
    const count = Number(headers["total-count"])

    if (count > 0) {
      setCollectionItemTotalCount(count)
    }

    setCollectionItems(data)
  }

  const fetchRestItems = async (storeId: Bee.ComboboxOption[0], count: number) => {
    const { data } = await iClient<CollectionItem[]>(`/stores/${storeId}/collection_items?per=${count}&outset=${PER}`)

    setRestItems(data)
  }

  const handleStoreChange = ([storeId]: Bee.ComboboxOption) => {
    setStoreId(storeId)
    setShowAllItems(false)
    setRestItems([])
  }

  const handleShowMore = () => {
    setShowAllItems(!showAllItems)
  }

  useEffect(() => {
    if (isNil(storeId)) return

    fetchCollectionItems(storeId)
  }, [storeId])

  useEffect(() => {
    if (!showAllItems) return
    if (isNil(storeId)) return
    if (restItems.length > 0) return
    if (collectionItemTotalCount <= PER) return

    fetchRestItems(storeId, collectionItemTotalCount - PER)
  }, [showAllItems])

  return (
    <RailsForm method="patch" className="h-inherit" action={`/quick_sends/${quickSend.id}`}>
      <div className="h-[calc(100vh-3.5rem)] flex flex-col gap-8 md:pb-6 pb-16">
        <WorkflowContainer className="flex flex-col grow gap-4 overflow-y-scroll">
          <WorkflowHeading title="Select your store" subtitle="Choose the store you would like to send items from" />

          <div className="flex flex-col items-center w-full sm:max-w-3xl mx-auto">
            <Bee.ComboboxInput
              name="quick_send[store_id]"
              className="self-stretch"
              defaultValue={quickSend.storeId}
              onChange={handleStoreChange}
              options={storeOptions}
            />
          </div>

          {collectionItems.length > 0 && (
            <div className="w-full sm:max-w-3xl mx-auto flex flex-col items-center gap-2">
              <h1 className="pb-2">In this store:</h1>

              <CollectionItemBatchGrid
                collectionItemBatches={
                  restItems.length > 0 && showAllItems ? [collectionItems, restItems] : [collectionItems]
                }
              />
              {collectionItemTotalCount > PER && !showAllItems && (
                <a className="ml-auto text-xs text-gray-500 underline cursor-pointer" onClick={handleShowMore}>
                  See all items
                </a>
              )}
            </div>
          )}
        </WorkflowContainer>

        <input name="step" type="hidden" defaultValue="store" />

        <div className="px-6 flex justify-center gap-4 bg-gray-50 w-full md:py-0 py-4 md:relative fixed bottom-0">
          <Bee.ButtonLink className="flex items-center gap-1" kind="secondary" href="/send_gifts">
            <ArrowLeftIcon className="h-4 w-4" />
            <span>Back</span>
          </Bee.ButtonLink>

          <Bee.Button type="submit" disabled={isNil(storeId)} className="flex items-center gap-1">
            <span>Continue</span>
            <ArrowRightIcon className="h-4 w-4" />
          </Bee.Button>
        </div>
      </div>
    </RailsForm>
  )
}
