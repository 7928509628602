import React from "react"
import { Bee } from "~/src/components/BeeKit"
import { AdjustmentsHorizontalIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { CatalogItem } from "~/src/serializedRecords"

function CatalogItems({ items }: { items: CatalogItem[] }) {
  return (
    <div className="flex justify-center h-full">
      {items.length === 0 ? (
        <div className="flex flex-col justify-center items-center p-8">
          <MagnifyingGlassIcon className="h-14 w-14 text-gray-300" />
          <h1 className="text-base font-medium leading-6 text-center pt-4">
            We couldn’t find what you were looking for
          </h1>
          <p className="text-sm text-center">
            Make sure all words are spelled correctly or try different search terms.
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export function ProductCatalogContent() {
  const tabs = [
    { label: "All products", ref: React.createRef() },
    { label: "Favorites", ref: React.createRef() },
    { label: "Hidden", ref: React.createRef() },
    { label: "Past Orders", ref: React.createRef() },
  ]

  return (
    <div className="flex flex-col mx-6 h-full">
      <Bee.Searchbar className="w-full mt-8" onSearch={(value: string) => alert(`Searching for: ${value}`)} />

      <Bee.TabBar className="mt-2">
        {tabs.map((tab, index) => (
          <Bee.Tab
            key={index}
            label={tab.label}
            isSelected={index === 0}
            ref={tab[index]}
            onClick={() => console.log("Tab clicked")}
          ></Bee.Tab>
        ))}
      </Bee.TabBar>

      <div className="flex items-center justify-between w-full mt-8">
        <div className="flex flex-row items-center hover:cursor-pointer">
          <AdjustmentsHorizontalIcon className="w-4 h-4 mr-1" />
          <span className="text-grey-900 text-sm leading-5">Filter</span>
        </div>

        <div className="text-grey-900 text-sm leading-5 flex items-center">
          <span className="mr-1 font-bold">Sort by</span>
          <span className="flex items-center hover:cursor-pointer">
            Newest <ChevronDownIcon className="w-3 h-3 text-gray-900" />
          </span>
        </div>
      </div>

      <CatalogItems items={[]} />
    </div>
  )
}
