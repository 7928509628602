import * as React from "react"

import { GiftIcon } from "@heroicons/react/24/outline"

import { twMerge } from "tailwind-merge"
import { RailsForm } from "~/src/components"
import { Store, StoreKitInstance, StoreKit } from "~/src/serializedRecords"
import { getFieldName } from "~/src/lib/forms"

import { KitItemsFieldGroups } from "./KitItemsFieldGroups"

interface StoreKitInstanceFormProps {
  formOptions: {
    path: string
    method: "get" | "post" | "patch" | "put"
  }
  storeKitInstance: Omit<StoreKitInstance, "id">
  storeKit: StoreKit
  store: Store
}

const getFormFieldName = (...path: string[]) => getFieldName("store_kit_instance", ...path)

export const inputClassnames = [
  "block",
  "w-full",
  "rounded-md",
  "border-0",
  "py-1.5",
  "text-gray-900",
  "shadow-sm",
  "ring-2",
  "ring-inset",
  "ring-gray-300",
  "placeholder:text-gray-400",
  "focus:ring-2",
  "focus:ring-inset",
  "focus:ring-indigo-600",
  "sm:text-sm",
  "sm:leading-6",
]

const button_classes = [
  "border-cyan-400",
  "border-2",
  "py-2",
  "px-4",
  "text-cyan-400",
  "enabled:hover:bg-cyan-400",
  "enabled:hover:text-white",
  "transition-colors",
  "disabled:border-gray-300",
  "disabled:text-gray-300",
]

export function StoreKitInstanceForm({
  formOptions: { method, path },
  store,
  storeKitInstance,
  storeKit,
}: StoreKitInstanceFormProps) {
  return (
    <RailsForm method={method} action={path}>
      <input type="hidden" name={getFormFieldName("store_kit_id")} value={storeKitInstance.storeKitId} />
      <section className="font-sans rounded-lg border-gray-300 border-2 overflow-hidden mb-6">
        <header className="p-4 bg-gray-50 flex gap-4">
          <div className="flex items-center">
            <GiftIcon className="h-6 w-6 text-gray-300" />
          </div>
          <div>
            <h2 className="text-gray-900 text-sm font-medium">Items included</h2>
            <p className="text-gray-500 text-xs">Make your selections before adding this item to your cart</p>
          </div>
        </header>
        <div>
          <KitItemsFieldGroups kitItems={storeKitInstance.kitItems} storeKit={storeKit} />
        </div>
      </section>
      {!store.giftPreview ? (
        <div>
          <label htmlFor="cart-quantity">Quantity</label>
          <div className="w-32">
            <input
              id="cart-quantity"
              className={twMerge(inputClassnames)}
              name={getFormFieldName("quantity")}
              min={1}
              type="number"
              defaultValue={storeKitInstance.quantity ?? 1}
            />
          </div>
        </div>
      ) : null}
      <div className="mb-6">
        {!store.hideStockCounts
          ? storeKit.inventoryRequired
            ? `(${storeKit.totalKitsPossible} in stock)`
            : "(on demand)"
          : null}
      </div>
      <button className={twMerge(button_classes)} disabled={!storeKit.available}>
        {store.giftPreview ? "Select Gift" : "Add to Cart"}
      </button>
    </RailsForm>
  )
}
