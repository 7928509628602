import React, { MouseEventHandler, ReactNode } from "react"

export type SelectableIconBoxProps = {
  value: string
  icon: ReactNode
  text: string
  name: string
  defaultChecked?: boolean
  onSelect?: MouseEventHandler<HTMLLabelElement>
}

export function SelectableIconBox(props: SelectableIconBoxProps) {
  const { name, value, icon, text, defaultChecked, onSelect } = props

  return (
    <div className="w-full">
      <input
        id={`${name}[${value}]`}
        type="radio"
        name={name}
        value={value}
        className="hidden peer"
        defaultChecked={defaultChecked}
      />
      <label
        htmlFor={`${name}[${value}]`}
        className="flex flex-col gap-4 rounded-lg place-content-center items-center peer-checked:border-gray-900 border border-gray-300 md:py-8 md:px-12 p-4 h-full w-full"
        onClick={onSelect}
      >
        {icon}
        <p className="text-gray-800 text-center">{text}</p>
      </label>
    </div>
  )
}
