import React, { PropsWithChildren, useState } from "react"
import { Transition } from "@headlessui/react"
import { Icon } from "~/src/components"
import { twMerge } from "tailwind-merge"

type SlideDirection = "left" | "right"

function getIndexTrio(currentIndex: number, maxIndex: number) {
  const nextIndex = currentIndex >= maxIndex ? 0 : currentIndex + 1
  const prevIndex = currentIndex <= 0 ? maxIndex : currentIndex - 1
  return [prevIndex, currentIndex, nextIndex]
}

/**
 * Simple multi-slide carousel. Takes children that represent the content to show each slide. With 0 or 1 child, no carousel ui is displayed. With two or more children, a dot progress bar shows the current child, and clicking the carousel advances it.
 *
 * @param props.children The children to display per-slide in the carousel
 */
export function Carousel({ className, children }: PropsWithChildren<{ className: string }>) {
  const childList = React.Children.toArray(children)
  const maxIndex = childList.length - 1

  if (childList.length <= 1) {
    return <div className={className}>{children}</div>
  }

  const [indexTrio, setIndexTrio] = useState(getIndexTrio(0, maxIndex))
  const [isSliding, setIsSliding] = useState(false)
  const [slideDirection, setSlideDirection] = useState<SlideDirection>("right")

  const startTransition = (direction: SlideDirection) => {
    if (!isSliding) {
      setSlideDirection(direction)
      setIsSliding(true)
      setIndexTrio((currentTrio) => {
        return direction === "right" ? getIndexTrio(currentTrio[2], maxIndex) : getIndexTrio(currentTrio[0], maxIndex)
      })
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { clientX, currentTarget } = e
    const clickPosition = clientX - currentTarget.getBoundingClientRect().left
    const direction: SlideDirection = clickPosition < currentTarget.clientWidth / 2 ? "left" : "right"
    startTransition(direction)
  }

  const transitionTo = (targetIndex: number) => {
    if (targetIndex === indexTrio[1]) return

    const direction = targetIndex > indexTrio[1] ? "right" : "left"
    setSlideDirection(direction)
    setIsSliding(true)
    setIndexTrio(getIndexTrio(targetIndex, maxIndex))
  }

  const endTransition = () => {
    setIsSliding(false)
  }

  return (
    <div className={twMerge(className, "cursor-pointer")}>
      <div className="flex flex-col w-full h-full">
        <div className="basis-5/6 grid w-full h-full overflow-x-hidden" onClick={handleClick}>
          {childList.map((child, index) => (
            <div key={index} className={indexTrio.includes(index) ? "col-span-full row-span-full" : "hidden"}>
              <Transition
                show={indexTrio[1] === index}
                enter={isSliding ? "transition duration-500" : ""}
                enterFrom={slideDirection === "right" ? "translate-x-full" : "-translate-x-full"}
                leave={isSliding ? "transition duration-500" : ""}
                leaveTo={slideDirection === "right" ? "-translate-x-full" : "translate-x-full"}
                afterLeave={endTransition}
              >
                <div>{child}</div>
              </Transition>
            </div>
          ))}
        </div>
        <div className="basis-1/6 flex justify-center items-end w-full gap-2 select-none">
          {childList.map((_, index) => (
            <div key={index} onClick={() => transitionTo(index)}>
              <Icon.Dot className={index === indexTrio[1] ? "text-navy-800" : "text-gray-200"} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
