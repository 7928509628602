import { Address } from "~/src/types/address"
import { isPresent } from "~/src/lib/any"
import { Country } from "~/src/lib/country"

export function addressLines(address: Address): string[] {
  const lines: string[] = []
  const cityStateZipLine = addressCityStateZipLine(address)

  if (isPresent(address.address1?.trim())) {
    lines.push(address.address1.trim())
  }

  if (isPresent(address.address2?.trim())) {
    lines.push(address.address2!.trim())
  }

  if (isPresent(cityStateZipLine)) {
    lines.push(cityStateZipLine)
  }

  if (isPresent(address.addressCountry)) {
    const countryName = address.addressCountry == "US" ? "USA" : Country.fromAlpha2(address.addressCountry).name
    lines.push(countryName)
  }

  if (typeof address.addressPhone === "string") {
    lines.push(address.addressPhone)
  }

  return lines
}

function addressCityStateZipLine(address: Address): string {
  const cityAndState: string[] = []
  if (isPresent(address.addressCity?.trim())) {
    cityAndState.push(address.addressCity.trim())
  }

  if (isPresent(address.addressState?.trim())) {
    cityAndState.push(address.addressState.trim())
  }

  let cityStateZipLine = cityAndState.join(", ")
  if (isPresent(address.addressZip)) {
    cityStateZipLine = `${cityStateZipLine} ${address.addressZip}`
  }

  return cityStateZipLine.trim()
}
