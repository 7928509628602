import React from "react"
import { twMerge } from "tailwind-merge"

import { Bee } from "~/src/components/BeeKit"

export type DisplayTagProps = {
  name: string
  logoUrl: string
  description?: string
  className?: string
  imgClassName?: string
}

const DisplayTagMainContent = ({ name, logoUrl, className, imgClassName }: DisplayTagProps) => (
  <span className={twMerge("flex gap-2 items-center", className)}>
    <img src={logoUrl} className={twMerge("h-6 w-6", imgClassName)} />
    <span className="font-roboto font-medium text-grey-900 text-xs">{name.toUpperCase()}</span>
  </span>
)

export function DisplayTag(props: DisplayTagProps) {
  const { name, description } = props

  if (description) {
    return (
      <span>
        <Bee.Tip key={name} placement="bottom-start">
          <Bee.TipTrigger>
            <DisplayTagMainContent {...props} />
          </Bee.TipTrigger>
          <Bee.TipBubble className="bg-black text-white rounded-none" showArrow={false}>
            <span className="text-sm">{description}</span>
          </Bee.TipBubble>
        </Bee.Tip>
      </span>
    )
  } else {
    return <DisplayTagMainContent {...props} />
  }
}
