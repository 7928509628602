// This file includes components used by our client facing UI.
import {
  BudgetDisplayPill,
  LegacyCatalog,
  ContactUs,
  CustomTraySolutionForm,
  CustomTraySolutionSelectorForm,
  DashboardDebugBox,
  DisplayTagList,
  DropshipEditor,
  LookbookProductFilter,
  OrderRecipientDelivery,
  ProductCatalogContent,
  QuickSendConfirmationContent,
  QuickSendEditDetailsContent,
  QuickSendEditDetailsContentLegacy,
  QuickSendEditSelectionsContent,
  QuickSendEditStoreContent,
  QuickSendReviewContent,
  ReactionStatistics,
  SignIn,
  Spreadsheet,
  StoreKitInstanceForm,
  StoreProductWarnings,
  StorefrontOrderHistoryControls,
  TeamForm,
  TeamsTable,
  ThankYouNoteConfirmation,
  ThankYouNoteLanding,
  ThankYouNoteMessageCarousel,
  ThankYouNotesIndex,
} from "~/src/components"
import { registerComponents } from "~/src/lib/reactComponentRegistry"

registerComponents({
  BudgetDisplayPill,
  LegacyCatalog,
  CustomTraySolutionForm,
  CustomTraySolutionSelectorForm,
  DashboardDebugBox,
  DisplayTagList,
  DropshipEditor,
  LookbookProductFilter,
  OrderRecipientDelivery,
  ProductCatalogContent,
  Spreadsheet,
  StorefrontOrderHistoryControls,
  StoreKitInstanceForm,
  TeamsTable,
  TeamForm,
  ThankYouNoteConfirmation,
  ThankYouNoteLanding,
  ThankYouNoteMessageCarousel,
  QuickSendEditDetailsContent,
  QuickSendEditDetailsContentLegacy,
  QuickSendEditStoreContent,
  QuickSendEditSelectionsContent,
  QuickSendConfirmationContent,
  StoreProductWarnings,
  ThankYouNotesIndex,
  ContactUs,
  ReactionStatistics,
  QuickSendReviewContent,
  SignIn,
})
