import React, { Children } from "react"
import { twMerge } from "tailwind-merge"

export type ButtonGroupProps = {
  className?: string
  children: JSX.Element | JSX.Element[]
}

type ChildPosition = "first" | "last" | "surrounded"

const borderProperties = (position: ChildPosition) => {
  switch (position) {
    case "first":
      return "border-t rounded-t-lg border-l border-r border-b-none border-b-0 rounded-b-none"
    case "surrounded":
      return "border-t rounded-t-none border-l border-r border-b-0 rounded-b-none"
    case "last":
      return "border-t rounded-t-none border-l border-r border-b rounded-b-lg"
  }
}

const cloneChildWithNewProperties = (child: JSX.Element, index: number, childrenSize: number) => {
  const position = index == 0 ? "first" : index == childrenSize - 1 ? "last" : "surrounded"

  return React.cloneElement(child, {
    className: twMerge(child.props.className, borderProperties(position)),
  })
}

/**
 * Accepts a list of Buttons or Inputs as children and "merges" the borders.
 * Expects the border color to be defined on the child.
 *
 * @param props.children a list of Bee.Button or Bee.ButtonLink components.
 *
 * @example
 * <Bee.ButtonGroup>
 *   <Bee.Button kind="primary"><span>Do this</span></Bee.Button>
 *   <Bee.Button kind="secondary" className="border-gray-300"><span>Do something else</span></Bee.Button>
 * </Bee.ButtonGroup>
 */
export function ButtonGroup(props: ButtonGroupProps) {
  const { className = "", children } = props

  if (!children) {
    return
  }

  const numChildren = Children.toArray(children).length

  return (
    <div className={twMerge("flex flex-col", className)}>
      {numChildren <= 1
        ? children
        : Children.map(children, (child, index) => {
            return cloneChildWithNewProperties(child, index, numChildren)
          })}
    </div>
  )
}
