import { PublishedStatus } from "./publishedStatus"
import { Logo } from "./logo"

export enum DisplayTagKind {
  SUSTAINABLE = "sustainable",
}

export type DisplayTag = {
  id: number
  name: string
  description?: string
  logo?: Logo
  kind: DisplayTagKind
  status: PublishedStatus
}
