import { RegistryFieldsType } from "@rjsf/utils"
import { BeforeOrAfterField } from "./BeforeOrAfterField"

export { BeforeOrAfterField }

const customFields: RegistryFieldsType = {
  "/schemas/beforeOrAfter": BeforeOrAfterField,
}

export default customFields
