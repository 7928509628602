import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import React, { Fragment, PropsWithChildren, useCallback, useEffect, useState } from "react"
import {
  Controller,
  FormProvider,
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
  useForm,
  useFormContext,
} from "react-hook-form"
import { twMerge } from "tailwind-merge"

import {
  AddressFieldImplementation,
  AddressFields,
  FileInput,
  ProductSelection,
  RailsForm,
  StoreProductWarnings,
  WorkflowContainer,
  WorkflowHeading,
} from "~/src/components"
import { isPresent, isntNil } from "~/src/lib/any"
import { summarizeMergeFields, summarizeSelections } from "~/src/lib/quickSend"
import { OrderRecipient, QuickSend, QuickSendKind } from "~/src/types/preferredGift"
import type { StoreProduct } from "~/src/types/productSelection"
import { Bee } from "./BeeKit"
import { QuickSendEditBaseErrorsFlash } from "./QuickSendEditBaseErrorsFlash"
import { QuickSendSummary } from "./QuickSendSummary"

// #region Types

type DetailsFormData = Record<string, string>
enum RecipientInformationUploadType {
  ManuallyAdd = 0,
  UploadCsv = 1,
}

type RecipientDetailsSingleUserSectionProps = { selected: boolean } & RecipientDetailsSectionProps

type RecipientAddressSectionProps = {
  selected: boolean
  quickSend: QuickSend
  watchUse: string
}

type RecipientDetailsSectionProps = {
  quickSend: QuickSend
  senderCompanyName: string
  watchUse: string
  formReturn: UseFormReturn<DetailsFormData>
  recipientInformationTabIndex: number
  setRecipientInformationTabIndex: React.Dispatch<React.SetStateAction<number>>
}

export type QuickSendEditDetailsContentLegacyProps = {
  quickSend: QuickSend
  senderCompanyName: string
  availableStoreBudget?: number
  customBudgetUnit?: string
}

// #endregion

// #region Constants

const ROUGH_EMAIL_REGEXP = /.+@.+\..+/

// #endregion

// #region Local Functions

function defaultEmailBody(companyName: string): string {
  return [
    "Hi {{ recipient_name }},",
    "",
    `${companyName} needs a little info from you so we can send you something.`,
    "It'll just take a minute! When you're ready, click the button below.",
    "",
    "Thanks,",
    "{{ sender_name }}",
  ].join("\n")
}

// #endregion

// #region Local Components

function BulkUploadCsvErrors({ errors }: { errors: string[] }) {
  const firstErrors = errors.slice(0, 20)
  const remainingErrors = errors.slice(20)
  const [showRemainingErrors, setShowRemainingErrors] = useState(false)

  if (errors.length > 0) {
    return (
      <div className="flex flex-col gap-1 rounded-lg bg-red-50 p-4">
        <h2 className="font-medium text-red-600">We found a few issues with your upload</h2>
        <p className="text-sm text-red-600">
          Please review and correct errors, then upload your file again to continue. If you continue to experience
          issues with this file, reach out to customer service.
        </p>
        <Disclosure>
          {({ open }) => (
            <div className="flex flex-col gap-4">
              <DisclosurePanel className="flex flex-col pt-1">
                <ul className="list-disc list-inside pl-1 text-sm text-gray-900">
                  {firstErrors.map((error, index) => (
                    <li key={`first-${index}`}>{error}</li>
                  ))}
                  {showRemainingErrors ? (
                    remainingErrors.map((error, index) => <li key={`remaining-${index}`}>{error}</li>)
                  ) : remainingErrors.length > 0 ? (
                    <button className="text-blue-600" type="button" onClick={() => setShowRemainingErrors(true)}>
                      Load remaining {remainingErrors.length} errors
                    </button>
                  ) : null}
                </ul>
              </DisclosurePanel>
              <DisclosureButton className="text-xs text-gray-600 flex gap-1 items-center">
                {open ? <span>Hide errors</span> : <span>Show errors</span>}
                {open ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
              </DisclosureButton>
            </div>
          )}
        </Disclosure>
      </div>
    )
  }
}

function DetailsSection({ title, description, children }: PropsWithChildren<{ title: string; description: string }>) {
  return (
    <div className="flex flex-col items-center gap-8">
      <br />
      <hr className="w-full" />
      <div className="flex flex-col lg:flex-row gap-8 w-full px-8">
        <hgroup className="flex flex-col basis-1/3 items-center lg:items-start">
          <h1 className="font-bold">{title}</h1>
          <h2 className="text-sm">{description}</h2>
        </hgroup>
        <div className="flex flex-col basis-2/3 items-center lg:items-start gap-2">{children}</div>
      </div>
    </div>
  )
}

function InventoryErrorFlash({ quickSend, hasInventory }: { quickSend: QuickSend; hasInventory: boolean }) {
  if (!hasInventory) {
    return (
      <Bee.Flash kind="error" className="mx-6 mt-4">
        <div className="flex flex-col gap-2 text-sm">
          <div className="flex flex-row gap-2 font-medium items-center">
            <ExclamationCircleIcon className="h-4 w-4" />
            <p>Insufficient inventory</p>
          </div>
          <p>
            We&apos;re sorry, one or more products in your send are no longer available. Please{" "}
            <a className="text-blue-600" href={`/quick_sends/${quickSend.id}/selections`}>
              edit your selection(s)
            </a>{" "}
            to resolve this issue and complete your send.
          </p>
        </div>
      </Bee.Flash>
    )
  }
}

function RecipientDetailsSingleUserSection(props: RecipientDetailsSingleUserSectionProps) {
  const { selected, quickSend, watchUse, formReturn } = props
  const {
    register,
    formState: { isDirty, errors },
  } = formReturn

  return (
    <fieldset className="flex flex-col gap-4 border border-gray-200 rounded-lg p-6">
      <section className="flex flex-col gap-y-2 lg:flex-row lg:flex-wrap lg:gap-x-4 w-full">
        <div className="basis-1/3 grow">
          <h2 className="font-medium">Recipient name</h2>

          <input
            className="w-full border border-gray-300 rounded"
            disabled={!selected}
            {...register("quick_send[order_recipients_attributes][0][name]", {
              required: { value: selected, message: "required" },
            })}
          />

          <Bee.FormError
            name="Name"
            initialError={quickSend.errors.orderRecipientsName}
            fieldIsDirty={isDirty}
            error={errors["quick_send"]?.["order_recipients_attributes"]?.[0]?.["name"]?.message}
          />
        </div>

        <div className="basis-1/3 grow">
          <h2 className="font-medium inline">
            Company
            <span className="text-sm text-gray-500 inline"> - Optional</span>
          </h2>

          <input
            className="w-full border border-gray-300 rounded"
            disabled={!selected}
            {...register("quick_send[order_recipients_attributes][0][company]")}
          />
          <Bee.FormError
            name="Company"
            initialError={quickSend.errors.orderRecipientsCompany}
            fieldIsDirty={isDirty}
            error={errors["quick_send"]?.["order_recipients_attributes"]?.[0]?.["company"]?.message}
          />
        </div>
      </section>
      {
        <section className={twMerge("flex flex-col w-full", watchUse !== "email" ? "hidden" : "")}>
          <div className="w-full">
            <h2 className="font-medium">Email address</h2>

            <input
              className="w-full border border-gray-300 rounded"
              disabled={!selected || watchUse !== "email"}
              {...register("quick_send[order_recipients_attributes][0][email]", {
                required: { value: selected && watchUse === "email", message: "required" },
                pattern: {
                  value: ROUGH_EMAIL_REGEXP,
                  message: "invalid",
                },
              })}
            />

            <Bee.FormError
              name="Email"
              initialError={quickSend.errors.orderRecipientsEmail}
              fieldIsDirty={isDirty}
              error={errors["quick_send"]?.["order_recipients_attributes"]?.[0]?.["email"]?.message}
            />
          </div>
        </section>
      }
      <RecipientAddressSection {...props} />
    </fieldset>
  )
}

function RecipientAddressSection(props: RecipientAddressSectionProps) {
  const { selected, quickSend } = props
  const {
    kind,
    preferenceCollector: { internationalAllowed = true, restrictedCountryCodes },
    orderRecipients: [orderRecipient],
  } = quickSend
  const { address } = orderRecipient
  const {
    formState: { errors },
  } = useFormContext()

  if (kind === QuickSendKind.Surprise) {
    return (
      <AddressFields
        implementation={AddressFieldImplementation.HOOK_FORM}
        serverErrors={address?.errors}
        formErrors={errors["quick_send"]?.["order_recipients_attributes"]?.[0]?.["address_attributes"]}
        includeCompanyField={false}
        includeNameField={false}
        internationalAllowed={internationalAllowed}
        restrictedCountryCodes={restrictedCountryCodes}
        fieldNamePrefix="quick_send[order_recipients_attributes][0][address_attributes]"
        disabled={!selected}
      />
    )
  }
}

function RecipientDetailsSection(props: RecipientDetailsSectionProps) {
  const { quickSend, watchUse, formReturn, recipientInformationTabIndex, setRecipientInformationTabIndex } = props
  const {
    control,
    trigger,
    formState: { isValid },
  } = formReturn

  const triggerTabValidation = useCallback(() => {
    trigger([
      "quick_send[order_recipients_attributes][0][name]",
      "quick_send[order_recipients_attributes][0][company]",
      "quick_send[order_recipients_attributes][0][email]",
      "quick_send[bulk_upload_csv]",
    ])
  }, [trigger])

  // Re-validate after re-rendering the recipient information tab
  useEffect(() => {
    triggerTabValidation()
  }, [triggerTabValidation, recipientInformationTabIndex])

  // Re-validate these fields whenever the form is otherwise valid
  useEffect(() => {
    if (isValid && watchUse == "email") {
      triggerTabValidation()
    }
  }, [triggerTabValidation, isValid])

  const persistedOrderRecipientCount = quickSend.orderRecipients.filter((recipient) => isntNil(recipient.id)).length
  const hasExistingBulkUpload = isPresent(quickSend.bulkUploadCsv) && persistedOrderRecipientCount > 0

  if (watchUse == "email") {
    return (
      <TabGroup defaultIndex={recipientInformationTabIndex} onChange={setRecipientInformationTabIndex}>
        <TabList className="flex w-full h-10 items-center text-sm font-medium mb-2">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                type="button"
                className={twMerge(
                  "w-full h-full border rounded-l-lg rounded-r-none",
                  selected ? "bg-navy-800 text-white border-navy-800" : "bg-white text-gray-900 border-gray-300"
                )}
              >
                Single Recipient
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                type="button"
                className={twMerge(
                  "w-full h-full border rounded-l-none rounded-r-lg",
                  selected ? "bg-navy-800 text-white border-navy-800" : "bg-white text-gray-900 border-gray-300"
                )}
              >
                Multiple Recipients
              </button>
            )}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel unmount={false}>
            {({ selected }) => <RecipientDetailsSingleUserSection selected={selected} {...props} />}
          </TabPanel>
          <TabPanel unmount={false}>
            {({ selected }) => (
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <h4 className="font-medium">Upload .csv</h4>
                  <div className="text-sm">
                    Bulk add your recipients by uploading a completed version of our .csv template. Need the template?
                    You can
                    <a className="text-blue-600" href={`/quick_sends/${quickSend.id}/csv_template`}>
                      {" "}
                      download it here
                    </a>
                    . On the next screen, you can review the recipients you&apos;ve uploaded.
                  </div>
                  <Disclosure>
                    {({ open }) => (
                      <div className="flex flex-col gap-4">
                        <DisclosureButton className="text-xs text-gray-600 flex gap-1 items-center">
                          <span>See requirements</span>
                          {open ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
                        </DisclosureButton>
                        <DisclosurePanel className="flex flex-col bg-gray-100 rounded-xl p-4 gap-4">
                          <div className="flex flex-col gap-1">
                            <h4 className="font-bold text-[10px]/4 tracking-wider text-gray-500 uppercase">Required</h4>
                            <ul className="list-disc list-inside pl-2 text-xs text-gray-900">
                              <li>recipient_name: First & last name of each gift recipient</li>
                              <li>recipient_email: Email address of each gift recipient</li>
                              {quickSend.kind == QuickSendKind.Surprise ? (
                                <>
                                  <li>address_1: Street address of each gift recipient</li>
                                  <li>address_city: City name of each gift recipient</li>
                                  <li>address_state: State of each gift recipient</li>
                                  <li>address_zip: Zip Ccde of each gift recipient</li>
                                  <li>
                                    address_country: Country of each gift recipient. Gifts cannot be sent to the
                                    following countries: Brazil, Argentina, India, Israel, Turkey, Taiwan, Saudi Arabia,
                                    Indonesia, China, Kenya, or any additional countries that have been restricted on
                                    your store.
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                          <div className="flex flex-col gap-1">
                            <h4 className="font-bold text-[10px]/4 tracking-wider text-gray-500 uppercase">Optional</h4>
                            <ul className="list-disc list-inside pl-2 text-xs text-gray-900">
                              <li>company_name: Company name of each gift recipient</li>
                              {quickSend.kind == QuickSendKind.Surprise ? (
                                <>
                                  <li>address_2: suite, Apartment, unit, etc. of each gift recipient</li>
                                  <li>address_phone: Phone number of each gift recipient</li>
                                </>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </DisclosurePanel>
                      </div>
                    )}
                  </Disclosure>
                </div>
                <Controller
                  control={control}
                  rules={{
                    required: selected && !hasExistingBulkUpload,
                  }}
                  name="quick_send[bulk_upload_csv]"
                  render={({ field: { onChange } }) => (
                    <div className="flex flex-col gap-4">
                      <BulkUploadCsvErrors errors={quickSend.bulkUploadCsvErrors} />
                      {hasExistingBulkUpload ? (
                        <p className="text-sm font-medium">
                          Existing upload: {quickSend.bulkUploadCsv} ({persistedOrderRecipientCount} recipients)
                        </p>
                      ) : null}
                      <FileInput
                        name="quick_send[bulk_upload_csv]"
                        disabled={!selected}
                        onDropAccepted={(acceptedFiles) => onChange(acceptedFiles[0])}
                        onClear={() => onChange("")}
                      />
                    </div>
                  )}
                />
              </div>
            )}
          </TabPanel>
        </TabPanels>
      </TabGroup>
    )
  } else {
    return <RecipientDetailsSingleUserSection selected={true} {...props} />
  }
}

function InvitationTypeSection({
  quickSendKind,
  orderRecipient,
  register,
  setValue,
}: {
  quickSendKind: QuickSendKind
  orderRecipient: OrderRecipient
  register: UseFormRegister<DetailsFormData>
  setValue: UseFormSetValue<DetailsFormData>
}) {
  const invitationOptions = [
    {
      value: "email",
      label: "Email",
      description: "We'll send your recipient a link to claim their gift via email",
    },
    {
      value: "link",
      label: "Link",
      description: "We'll generate a unique url that you can share directly with your recipient",
    },
  ]

  // TODO: setValue seems to be typed to `never`, which isn't right...
  if (quickSendKind === QuickSendKind.Standard) {
    return (
      <DetailsSection title="Invitation type" description="How do you want to send the invitation to this gift?">
        <input
          hidden
          {...register("quick_send[order_recipients_attributes][0][use]", {
            required: { value: true, message: "required" },
          })}
        />
        <Bee.RadioGroup
          onChange={(value) =>
            setValue("quick_send[order_recipients_attributes][0][use]", value as never, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
          defaultValue={orderRecipient.use || "email"}
          options={invitationOptions}
        />
      </DetailsSection>
    )
  }
}

function EmailMessageField({
  quickSend,
  watchUse,
  isDirty,
  multipleRecipients,
  register,
}: {
  quickSend: QuickSend
  watchUse: string
  isDirty: boolean
  multipleRecipients: boolean
  register: UseFormRegister<DetailsFormData>
}) {
  const { kind, errors } = quickSend

  if (kind === QuickSendKind.Standard) {
    return (
      <div className={twMerge("flex flex-col gap-1", watchUse !== "email" ? "hidden" : "")}>
        <h1 className="font-medium">Email message</h1>
        <Bee.FormError
          name="Email Message"
          initialError={quickSend.errors.emailBody}
          fieldIsDirty={isDirty}
          error={errors["quick_send"]?.["email_body"]?.message}
        />
        {multipleRecipients ? (
          <h2 className="text-sm">
            This is the message that all uploaded recipients will see on their invitation email. Please note that{" "}
            <span className="whitespace-nowrap">{"{{ recipient_name }}"}</span> and{" "}
            <span className="whitespace-nowrap">{"{{ sender_name }}"}</span> will not show up on the invitation email.
            This will be replaced by the actual recipients names in the csv and the sender name entered on this form.
          </h2>
        ) : (
          <h2 className="text-sm">
            This is the message that your recipient will see on their invitation email. Please note that{" "}
            <span className="whitespace-nowrap">{"{{ recipient_name }}"}</span> and{" "}
            <span className="whitespace-nowrap">{"{{ sender_name }}"}</span> will not show up on the invitation email.
            This will be replaced by the actual recipient&apos;s name and sender name that has been entered on this
            form.
          </h2>
        )}
        <textarea
          className="w-full border border-gray-300 rounded"
          rows={7}
          disabled={watchUse !== "email"}
          {...register("quick_send[email_body]", {
            required: { value: watchUse === "email", message: "required" },
          })}
        />
      </div>
    )
  }
}

// #endregion

// #region Component

export function QuickSendEditDetailsContentLegacy(props: QuickSendEditDetailsContentLegacyProps) {
  const { quickSend, senderCompanyName, availableStoreBudget, customBudgetUnit } = props
  const { kind, selections, preferenceCollector, orderRecipients: [orderRecipient] = [] } = quickSend
  const { internationalAllowed = true, restrictedCountryCodes } = preferenceCollector
  const { address } = orderRecipient

  const defaultOrderRecipientName = orderRecipient.name || ""
  const defaultSenderName = orderRecipient.senderName || ""
  const defaultCountry = address?.addressCountry || "US"
  const defaultAddressCountry =
    internationalAllowed && restrictedCountryCodes.length > 0 && !restrictedCountryCodes.includes(defaultCountry)
      ? restrictedCountryCodes[0]
      : defaultCountry

  const formReturn = useForm<DetailsFormData>({
    mode: "onBlur",
    defaultValues: {
      "quick_send[order_recipients_attributes][0][sender_name]": defaultSenderName,
      "quick_send[order_recipients_attributes][0][sender_email]": orderRecipient.senderEmail || "",
      "quick_send[order_recipients_attributes][0][use]": orderRecipient.use || "email",
      "quick_send[order_recipients_attributes][0][name]": defaultOrderRecipientName,
      "quick_send[order_recipients_attributes][0][company]": orderRecipient.company || "",
      "quick_send[order_recipients_attributes][0][email]": orderRecipient.email || "",
      "quick_send[order_recipients_attributes][0][address_attributes][address_country]": defaultAddressCountry,
      "quick_send[order_recipients_attributes][0][address_attributes][address_1]": address?.address1 || "",
      "quick_send[order_recipients_attributes][0][address_attributes][address_2]": address?.address2 || "",
      "quick_send[order_recipients_attributes][0][address_attributes][address_city]": address?.addressCity || "",
      "quick_send[order_recipients_attributes][0][address_attributes][address_state]": address?.addressState || "",
      "quick_send[order_recipients_attributes][0][address_attributes][address_zip]": address?.addressZip || "",
      "quick_send[order_recipients_attributes][0][address_attributes][address_phone]": address?.addressPhone || "",
      "quick_send[email_body]": quickSend.emailBody || defaultEmailBody(senderCompanyName),
      "quick_send[notecard_message]": quickSend.notecardMessage || "",
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty, isValid, errors },
  } = formReturn

  const watchUse = watch("quick_send[order_recipients_attributes][0][use]")

  const allHaveInventory = quickSend.selections.every(
    ({ selectable: { inventoryCount, inventoryRequired } }) => !inventoryRequired || inventoryCount > 0
  )
  const { chosenSelections, giftedSelections, estimatedTotal } = summarizeSelections(selections)

  const initialTab =
    quickSend.bulkUploadCsvErrors.length > 0 || isPresent(quickSend.bulkUploadCsv)
      ? RecipientInformationUploadType.UploadCsv
      : RecipientInformationUploadType.ManuallyAdd
  const [recipientInformationTabIndex, setRecipientInformationTabIndex] = useState<number>(initialTab)

  const multipleRecipients =
    watchUse === "email" && recipientInformationTabIndex === RecipientInformationUploadType.UploadCsv

  const onSubmit = (e: React.BaseSyntheticEvent) => {
    let warningMessage = ""
    const notecardFieldText = e.target.querySelector('[name="quick_send[notecard_message]"]').value
    const [, invalidNotecardMergeFields] = summarizeMergeFields(notecardFieldText)
    let allUnsupportedMergeFields = invalidNotecardMergeFields

    if (kind === QuickSendKind.Standard) {
      const emailFieldText = e.target.querySelector('[name="quick_send[email_body]"]').value
      const [validEmailMergeFields, invalidEmailMergeFields] = summarizeMergeFields(emailFieldText)
      const emailMissingRecipientName = !validEmailMergeFields.includes("recipient_name")

      if (watchUse === "email" && emailMissingRecipientName) {
        warningMessage +=
          "Couldn’t find {{ recipient_name }} in the email body. Are you sure you want to send recipient emails without their name?\n\n"
      }
      allUnsupportedMergeFields = allUnsupportedMergeFields.concat(invalidEmailMergeFields)
    }

    if (allUnsupportedMergeFields.length > 0) {
      warningMessage += `We found the following unsupported fields: ${allUnsupportedMergeFields.map((field) => `{{ ${field} }}`).join(", ")}.\n\n`
    }

    const isConfirmed = warningMessage.length > 0 ? confirm(`${warningMessage}Would you still like to continue?`) : true

    isConfirmed ? handleSubmit(() => null)() : e.preventDefault()
  }

  return (
    <FormProvider {...formReturn}>
      <RailsForm
        method="patch"
        className="h-inherit"
        action={`/quick_sends/${quickSend.id}`}
        encType="multipart/form-data"
        onSubmit={onSubmit}
      >
        <div className="h-[calc(100vh-3.5rem)] flex flex-col gap-8 md:pb-6 pb-16">
          <WorkflowContainer className="grow px-0 sm:px-0 lg:px-0 overflow-y-scroll">
            <WorkflowHeading title="Send details" subtitle="Review your send and add recipient information" />

            <InventoryErrorFlash quickSend={quickSend} hasInventory={allHaveInventory} />
            <QuickSendEditBaseErrorsFlash quickSend={quickSend} />

            {/* Products */}
            <DetailsSection title="Products" description="Items that will be included in this send">
              <div className="w-full flex flex-col">
                <QuickSendSummary
                  quickSendKind={kind}
                  chosenSelections={chosenSelections}
                  giftedSelections={giftedSelections}
                  estimatedTotal={estimatedTotal}
                  renderStoreProductDetails={(storeProduct: StoreProduct) => (
                    <StoreProductWarnings quickSendId={quickSend.id} storeProduct={storeProduct} />
                  )}
                />
              </div>
              <ProductSelection.BudgetStatusPill budget={availableStoreBudget} currencyUnit={customBudgetUnit} />
            </DetailsSection>

            {/*Sender*/}
            <DetailsSection title="Sender details" description="Confirm who this gift is being sent from">
              <label className="mr-auto font-medium">Sender name</label>
              <Bee.FormError
                name="Sender name"
                fieldIsDirty={isDirty}
                initialError={quickSend.errors.orderRecipientsSenderName}
                error={errors["quick_send"]?.["order_recipients_attributes"]?.[0]?.["sender_name"]?.message}
              />
              <input
                className="w-full border border-gray-300 rounded"
                {...register("quick_send[order_recipients_attributes][0][sender_name]", {
                  required: { value: true, message: "required" },
                })}
              />

              <label className="mr-auto font-medium">Email Address</label>
              <Bee.FormError
                name="Sender email"
                initialError={quickSend.errors.orderRecipientsSenderEmail}
                fieldIsDirty={isDirty}
                error={errors["quick_send"]?.["order_recipients_attributes"]?.[0]?.["sender_email"]?.message}
              />
              <input
                className="w-full border border-gray-300 rounded"
                {...register("quick_send[order_recipients_attributes][0][sender_email]", {
                  required: { value: true, message: "required" },
                  pattern: {
                    value: ROUGH_EMAIL_REGEXP,
                    message: "invalid",
                  },
                })}
              />
            </DetailsSection>

            {/*Invitation*/}
            <InvitationTypeSection
              quickSendKind={kind}
              orderRecipient={orderRecipient}
              register={register}
              setValue={setValue}
            />

            {/*Recipient*/}
            <DetailsSection title="Recipient information" description="Tell us who you'd like to send this gift to">
              <section className="flex flex-col gap-6 w-full">
                <RecipientDetailsSection
                  quickSend={quickSend}
                  senderCompanyName={senderCompanyName}
                  watchUse={watchUse}
                  formReturn={formReturn}
                  recipientInformationTabIndex={recipientInformationTabIndex}
                  setRecipientInformationTabIndex={setRecipientInformationTabIndex}
                />
              </section>
            </DetailsSection>

            {/*Message*/}
            <DetailsSection title="Message" description="Add a custom message to this shipment">
              <section className="flex flex-col gap-4">
                <EmailMessageField
                  quickSend={quickSend}
                  watchUse={watchUse}
                  isDirty={isDirty}
                  multipleRecipients={multipleRecipients}
                  register={register}
                />

                <div className="flex flex-col gap-1">
                  <h1 className="font-medium inline">
                    Notecard
                    <span className="text-sm text-gray-500 inline"> - Optional</span>
                  </h1>
                  <Bee.FormError
                    name="Notecard"
                    initialError={quickSend.errors.notecardMessage}
                    fieldIsDirty={isDirty}
                    error={errors["quick_send"]?.["notecard_message"]?.message}
                  />
                  {multipleRecipients ? (
                    <h2 className="text-sm">
                      This is the message that will be printed on the physical notecard included in your shipments and
                      will be sent to all recipients. If all text is removed, a notecard will not be included in your
                      shipment. Please note that <span className="whitespace-nowrap">{"{{ sender_name }}"}</span> and{" "}
                      <span className="whitespace-nowrap">{"{{ company_name }}"}</span> will not show up on the
                      notecard. This will be replaced by the sender name that has been entered on this form and the
                      company’s name
                    </h2>
                  ) : (
                    <h2 className="text-sm">
                      This is the message that will be printed on the physical notecard included in your shipment. If
                      all text is removed, a notecard will not be included in your shipment. Please note that{" "}
                      <span className="whitespace-nowrap">{"{{ sender_name }}"}</span> and{" "}
                      <span className="whitespace-nowrap">{"{{ company_name }}"}</span> will not show up on the
                      notecard. This will be replaced by the sender name that has been entered on this form and the
                      company&apos;s name.
                    </h2>
                  )}

                  <textarea
                    className="w-full border border-gray-300 rounded"
                    rows={5}
                    {...register("quick_send[notecard_message]")}
                  />
                </div>
              </section>
            </DetailsSection>
          </WorkflowContainer>

          <input
            name="quick_send[order_recipients_attributes][0][preference_collector_id]"
            type="hidden"
            defaultValue={preferenceCollector.id}
          />
          <input
            type="hidden"
            name="quick_send[order_recipients_attributes][0][estimated_total]"
            value={estimatedTotal}
          />

          <input name="step" type="hidden" defaultValue="details" />

          <div className="px-6 flex justify-center gap-4 bg-gray-50 w-full md:py-0 py-4 md:relative fixed bottom-0">
            <Bee.ButtonLink
              className="flex items-center gap-1"
              kind="secondary"
              href={`/quick_sends/${quickSend.id}/selections`}
            >
              <ArrowLeftIcon className="h-4 w-4" />
              <span>Back</span>
            </Bee.ButtonLink>

            <Bee.Button type="submit" className="flex items-center gap-1" disabled={!isValid && allHaveInventory}>
              {watchUse === "email" ? "Review send" : "Send gift!"}
            </Bee.Button>
          </div>
        </div>
      </RailsForm>
    </FormProvider>
  )
}

// #endregion
