import * as React from "react"
import { twMerge } from "tailwind-merge"
import { inputClassnames } from "~/src/components/StoreKitInstances/StoreKitInstanceForm"

interface KitItemVariantFieldProps {
  availableVariantIds: Array<number>
  inputName: string
  variantOptions: Array<{ id: number; label: string }>
}

export function KitItemVariantField({ availableVariantIds, inputName, variantOptions }: KitItemVariantFieldProps) {
  if (availableVariantIds.length > 1) {
    return (
      <div className="variant">
        <div className="input select required">
          <select name={inputName} className={twMerge(inputClassnames)}>
            {variantOptions.map((variantOption) => (
              <option
                disabled={!availableVariantIds.includes(variantOption.id)}
                key={variantOption.id}
                value={variantOption.id}
              >
                {variantOption.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  } else {
    return <input type="hidden" name={inputName} value={availableVariantIds[0]} />
  }
}
