import React, { useState } from "react"
import { Bee } from "~/src/components/BeeKit"
import { Selectable, StoreProduct, Variant } from "~/src/types/productSelection"
import { Modal } from "~/src/components/Modal"
import { ImageWithFallback } from "~/src/components/ImageWithFallback"
import { QuickSendSelectionKind } from "~/src/types/preferredGift"
import { InformationCircleIcon } from "@heroicons/react/20/solid"
import { Format } from "~/src/lib/format"
import { isntNil } from "~/src/lib/any"
import { ProductSelection } from "."
import { twMerge } from "tailwind-merge"

export type VariantDetailModalProps = {
  selectable: Selectable
  selectedVariant?: Variant
  modalIsOpen: boolean
  setModalIsOpen: (open: boolean) => void
  onChoice: (kind: QuickSendSelectionKind, selectedVariant?: Variant) => void
}

export function VariantDetailModal(props: VariantDetailModalProps) {
  const { selectable, selectedVariant, modalIsOpen, setModalIsOpen, onChoice } = props
  const storeProduct = selectable as StoreProduct
  const { sizeCount } = storeProduct

  const [tempVariant, setTempVariant] = useState<Variant>(selectedVariant || ({} as Variant))

  return (
    <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} className="bg-white md:h-[30rem]" usePortal={false}>
      <div className="flex flex-col gap-2 items-center bg-white">
        <div className="flex md:flex-row flex-col gap-4">
          <Header selectable={selectable} className="md:hidden flex gap-1" />
          <ProductImage variant={tempVariant} selectable={selectable} />
          <div className="flex flex-col gap-6">
            <Header selectable={selectable} className="md:flex hidden border-gray-200 border-b pb-6" />

            <div className="flex flex-col gap-2">
              <SizeWarning sizeCount={sizeCount} />
              <ProductSelection.VariantDropdown
                variants={storeProduct.variants}
                selectedVariant={tempVariant}
                inventoryRequired={selectable?.inventoryRequired ?? true}
                onChange={setTempVariant}
                label={dropdownLabel(storeProduct)}
              />
            </div>
            <Bee.Button
              onClick={() => {
                setModalIsOpen(false)
                onChoice(QuickSendSelectionKind.Gifted, tempVariant)
              }}
              disabled={!tempVariant.id}
            >
              Add as included gift
            </Bee.Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

function ProductImage({ variant, selectable }: { variant: Variant; selectable: Selectable }) {
  return (
    <div className="flex place-content-center">
      <ImageWithFallback
        className="flex md:w-96 w-full"
        imgProps={{ className: "md:w-96 w-full" }}
        src={variant?.imageUrl || selectable.imageUrl}
      />
    </div>
  )
}

function SizeWarning({ sizeCount }: { sizeCount: number }) {
  if (sizeCount > 1) {
    return (
      <div className="flex  bg-gray-100 p-2 gap-2 md:w-80 rounded-lg">
        <div className="h-4 w-4 pt-1">
          <InformationCircleIcon className="h-4 w-4 text-gray-400" />
        </div>
        <p className="text-sm text-gray-900">
          We don&apos;t recommend adding apparel items with size as an included gift since the recipient will not be
          able to select their preferred size.
        </p>
      </div>
    )
  }
}

function dropdownLabel(storeProduct: StoreProduct) {
  const { colorCount, flavorCount, sizeCount } = storeProduct

  const availableVariants = new Array<string>()
  if (colorCount > 1) {
    availableVariants.push("Color")
  }
  if (sizeCount > 1) {
    availableVariants.push("Size")
  }
  if (flavorCount > 1) {
    availableVariants.push("Flavor")
  }

  return availableVariants.join(" / ")
}

function Header({ selectable, className }: { selectable: Selectable; className?: string }) {
  const { name, displayPrice, customCurrencyUnit } = selectable

  return (
    <hgroup className={twMerge("flex flex-col gap-2", className)}>
      <h1 className="text-gray-900 text-2xl font-medium">{name}</h1>
      <h2 className="text-gray-900 text-lg">
        {isntNil(customCurrencyUnit)
          ? Format.customCurrency(displayPrice, customCurrencyUnit)
          : Format.usd(displayPrice)}
      </h2>
    </hgroup>
  )
}
