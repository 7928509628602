import React, { ReactNode } from "react"
import { twMerge } from "tailwind-merge"

export type ColumnDef<RowData> = {
  name: string
  headerNode?: ReactNode
  className?: string
  renderCell: RenderCell<RowData>
}

export type RenderCell<RowData> = string | ((row: RowData) => string | ReactNode)

export type DataTableProps<RowData> = {
  data: Array<RowData>
  columnDefs: Array<ColumnDef<RowData>>
  className?: string
  keyFn?: (row: RowData) => number
}

export function DataTable<RowData>(props: DataTableProps<RowData>) {
  const { columnDefs, className } = props

  return (
    <div className={twMerge("border border-gray-200 rounded-lg overflow-hidden", className)}>
      <table className="w-full border-collapse table-auto bg-white">
        <TableHeader columnDefs={columnDefs} />
        <TableBody {...props} />
      </table>
    </div>
  )
}

function TableHeader<RowData>({ columnDefs }: { columnDefs: Array<ColumnDef<RowData>> }) {
  return (
    <thead className="text-left">
      <tr className="bg-gray-100">
        {columnDefs.map(({ name, headerNode, className }, index) => {
          return (
            <th key={index} className={twMerge("px-6 py-4", className)}>
              {headerNode ? headerNode : name}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

function TableBody<RowData>({ data, columnDefs, keyFn }: DataTableProps<RowData>) {
  return (
    <tbody className="divide-y">
      {data.map((row, index) => (
        <TableRow key={keyFn ? keyFn(row) : index} row={row} columnDefs={columnDefs} />
      ))}
    </tbody>
  )
}

function TableRow<RowData>({ row, columnDefs }: { row: RowData; columnDefs: ColumnDef<RowData>[] }) {
  return (
    <tr>
      {columnDefs.map(({ className, renderCell }, index) => {
        return (
          <td key={index} className={twMerge("px-6 py-4 align-top", className)}>
            {typeof renderCell == "string" ? row[renderCell] : renderCell(row)}
          </td>
        )
      })}
    </tr>
  )
}
