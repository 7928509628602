import React, { useEffect } from "react"
import { Bee } from "./BeeKit"
import { DashBox, DashBoxHeading } from "./DashBox"
import { twMerge } from "tailwind-merge"

/**
 * Debug box component for the dashboard that can be shown by calling `window.__showDebugBox()` in console as a
 * AdminUser with role `admin`.
 */
export function DashboardDebugBox() {
  const [visible, setVisible] = React.useState(false)

  useEffect(() => {
    window.__showDebugBox = () => setVisible(true)

    return () => {
      window.__showDebugBox = undefined
    }
  }, [])

  return (
    <DashBox className={twMerge("hidden", visible && "block")}>
      <DashBoxHeading title="Debugging Tools" />
      <Bee.Button
        className="bg-red-500 border-red-500"
        onClick={() => {
          throw new Error("Aaaah! I have errored. Woe is me. 😭")
        }}
      >
        Throw Error
      </Bee.Button>
    </DashBox>
  )
}

declare global {
  interface Window {
    __showDebugBox?: () => void
  }
}
