import React, { ReactNode } from "react"
import { WorkflowHeading } from "~/src/components/WorkflowHeading"
import { QuickSend, QuickSendKind } from "~/src/types/preferredGift"
import { summarizeSelections } from "~/src/lib/quickSend"
import { QuickSendSummary } from "~/src/components/QuickSendSummary"
import { Bee } from "~/src/components/BeeKit"
import { ProductSelection } from "~/src/components/ProductSelection"
import { BudgetAmount } from "~/src/types/budget"
import { Format } from "~/src/lib/format"
import { RailsForm } from "./RailsForm"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { WorkflowContainer } from "./WorkflowContainer"

export type QuickSendReviewContentProps = {
  quickSend: QuickSend
  availableStoreBudget: BudgetAmount
  customBudgetUnit?: string
}

export function QuickSendReviewContent(props: QuickSendReviewContentProps) {
  const { quickSend, availableStoreBudget, customBudgetUnit } = props
  const { kind, selections, orderRecipients, emailBody, notecardMessage } = quickSend
  const { use } = orderRecipients[0]

  const { chosenSelections, giftedSelections, estimatedTotal } = summarizeSelections(selections)

  const estimatedTotalForAll = estimatedTotal * orderRecipients.length
  return (
    <RailsForm method="patch" className="h-inherit" action={`/quick_sends/${quickSend.id}`}>
      <div className="h-[calc(100vh-3.5rem)] flex flex-col gap-8 md:pb-6 pb-16">
        <WorkflowContainer className="grow px-0 sm:px-0 lg:px-0 overflow-y-scroll">
          <div className="bg-white rounded-lg sm:mt-8 sm:mx-6 flex flex-col flex-grow  divide-y divide-gray-100">
            <div className="flex flex-col md:px-12 md:pt-16 p-6">
              <WorkflowHeading
                title="Review your send"
                subtitle='Cross your "t"s and dot your "i"s by taking one more look at your send details'
              />
            </div>
            <ReviewSection title="Products">
              <QuickSendSummary
                quickSendKind={kind}
                chosenSelections={chosenSelections}
                giftedSelections={giftedSelections}
              />
              <div className="flex md:flex-row flex-col justify-between w-full gap-2">
                <div className="flex md:flex-row flex-col gap-2">
                  <p className="font-medium text-gray-700">
                    Estimated total: {Format.currency(estimatedTotalForAll, customBudgetUnit)}
                  </p>
                  <p className="text-gray-500">
                    ({Format.currency(estimatedTotal, customBudgetUnit)} per send x {orderRecipients.length} recipients)
                  </p>
                </div>
                <ProductSelection.BudgetStatusPill
                  budget={availableStoreBudget}
                  currencyUnit={customBudgetUnit}
                  estimatedCharges={estimatedTotalForAll}
                />
              </div>
            </ReviewSection>
            <ReviewSection title={`Recipients (${orderRecipients.length})`}>
              <ProductSelection.RecipientTable orderRecipients={orderRecipients} />
            </ReviewSection>
            {kind == QuickSendKind.Surprise && notecardMessage ? (
              <ReviewSection title="Notecard">
                <div className="flex flex-col gap-4 w-full">
                  <MessageBox message={notecardMessage} />
                </div>
              </ReviewSection>
            ) : kind == QuickSendKind.Standard && (use == "email" || notecardMessage) ? (
              <ReviewSection title="Message">
                <div className="md:grid md:grid-cols-2 flex flex-col gap-4 w-full">
                  {use == "email" ? <MessageBox title="Email" message={emailBody} /> : <></>}
                  {notecardMessage ? <MessageBox title="Notecard" message={notecardMessage} /> : <></>}
                </div>
              </ReviewSection>
            ) : (
              <></>
            )}
          </div>
        </WorkflowContainer>
        <input name="step" type="hidden" defaultValue="review" />
        <input name="quick_send[id]" type="hidden" defaultValue={quickSend.id} />

        <div className="px-6 md:flex hidden justify-between gap-4 bg-gray-50 w-full md:py-0 py-4 md:relative fixed bottom-0">
          <Bee.ButtonLink
            className="flex items-center gap-1 border-gray-300"
            kind="secondary"
            href={`/quick_sends/${quickSend.id}/details`}
          >
            <ArrowLeftIcon className="h-4 w-4" />
            <span>Back</span>
          </Bee.ButtonLink>

          <Bee.Button type="submit" className="flex items-center gap-1">
            Send gift
          </Bee.Button>
        </div>
        <div className="px-6 flex md:hidden flex-col justify-center gap-4 bg-gray-50 w-full py-6 md:relative fixed bottom-0">
          <Bee.Button type="submit" className="flex justify-center w-full">
            Send gift
          </Bee.Button>

          <Bee.ButtonLink
            className="flex justify-center w-full gap-1 items-center"
            kind="secondary"
            href={`/quick_sends/${quickSend.id}/details`}
          >
            <ArrowLeftIcon className="h-4 w-4" />
            <span>Back</span>
          </Bee.ButtonLink>
        </div>
      </div>
    </RailsForm>
  )
}

function ReviewSection({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div className="flex flex-col px-8 lg:px-20 py-8 gap-4">
      <h2 className="text-xl font-medium text-gray-700">{title}</h2>
      {children}
    </div>
  )
}

function MessageBox({ title, message }: { title?: string; message: string }) {
  return (
    <div className="flex flex-col gap-2">
      {title ? <h2 className="text-xl font-medium text-gray-900">{title}</h2> : null}
      <div className="border border-gray-200 p-4 rounded-lg">
        <p className="whitespace-pre-wrap text-gray-900">{message}</p>
      </div>
    </div>
  )
}
