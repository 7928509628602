import React from "react"
import { Bee } from "./BeeKit"
import { SubmitHandler, useForm } from "react-hook-form"
import { twMerge } from "tailwind-merge"

export type ContactUsProps = {
  name?: string
  email?: string
  url: string
  brilliantUrl: string
  formClassName?: string
  onSubmit?: SubmitHandler<ContactUsForm>
}

type ContactUsForm = {
  name: string
  email: string
  message: string
}

export function ContactUs(props: ContactUsProps) {
  const { url, name, email, brilliantUrl, formClassName, onSubmit } = props

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<ContactUsForm>({ defaultValues: { name, email } })

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-3xl font-medium text-navy-800 text-center">Contact Brilliant</h1>
      <form
        className={twMerge(
          "grid md:grid-cols-2 grid-cols-1 gap-4 md:py-10 md:px-16 p-6 bg-white rounded-lg",
          formClassName
        )}
        onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}
        action={url}
        method="post"
      >
        <div className="flex flex-col gap-1 w-full">
          <label className="text-gray-700 text-sm font-medium">Name</label>
          <input
            className="border border-gray-300 rounded"
            type="text"
            {...register("name", { required: { value: true, message: "required" } })}
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label className="text-gray-700 text-sm font-medium">Email address</label>
          <input
            className="border border-gray-300 rounded"
            type="text"
            {...register("email", { required: { value: true, message: "required" } })}
          />
        </div>
        <div className="flex flex-col gap-1 w-full col-span-full">
          <label className="text-gray-700 text-sm font-medium">Message</label>
          <textarea
            className="border border-gray-300 rounded"
            rows={5}
            {...register("message", { required: { value: true, message: "required" } })}
          ></textarea>
        </div>
        <Bee.Button className="mt-2 col-span-full" disabled={!isValid}>
          Submit
        </Bee.Button>
      </form>
      <p className="text-gray-400 text-center">
        Your shipment was sent by our partner,{" "}
        <a className="underline" href={brilliantUrl}>
          Brilliant
        </a>
      </p>
    </div>
  )
}
