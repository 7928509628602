import React from "react"
import { Bee } from "~/src/components/BeeKit"
import { Menu } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid"

export type SignInProps = { signInUrl: string }

export function SignIn(props: SignInProps) {
  const { signInUrl } = props

  return (
    <>
      <div className="md:flex hidden flex-row gap-6 items-center">
        <a href={signInUrl} className="text-sm text-center">
          Sign in
        </a>
        <Bee.ButtonLink
          href="https://www.brilliantmade.com/get-started"
          className="text-white text-sm bg-magenta-500 border-none"
        >
          Get started
        </Bee.ButtonLink>
      </div>
      <Menu as="div" className="md:hidden flex">
        <Menu.Button>
          {({ open }) => (open ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />)}
        </Menu.Button>
        <Menu.Items className="flex flex-col gap-1 absolute bg-white w-full p-4 right-0 origin-top mt-10 border-b border-gray-200 shadow-lg">
          <Menu.Item>
            <Bee.ButtonLink
              href="https://www.brilliantmade.com/get-started"
              className="text-white bg-magenta-500 border-none"
            >
              Get started
            </Bee.ButtonLink>
          </Menu.Item>
          <Menu.Item>
            <Bee.ButtonLink kind="secondary" href={signInUrl} className="border-gray-200">
              Sign in
            </Bee.ButtonLink>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </>
  )
}
