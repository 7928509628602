import React, { useMemo, useState } from "react"

import { Bee } from "~/src/components/BeeKit"
import { RailsForm } from "~/src/components/RailsForm"
import { SolutionForSetup } from "~/src/types/solution"

import { CustomTraySolutionForm } from "./CustomTraySolutionForm"

type CustomTraySolutionSelectorFormProps = {
  redirectOnSuccess?: string
  storeId: number
  solutions: SolutionForSetup[]
}

export function CustomTraySolutionSelectorForm({
  redirectOnSuccess,
  storeId,
  solutions,
}: CustomTraySolutionSelectorFormProps) {
  const [solutionId, setSolutionId] = useState<number>(solutions[0].id)
  const solutionOptions: Bee.ComboboxOption[] = solutions.map((solution) => [solution.id, solution.title])
  const currentSolution = useMemo(
    () => solutions.find((solution) => solution.id === solutionId),
    [solutionId, solutions]
  )

  return (
    <>
      <div className="flex flex-col gap-4 mx-6 my-2 p-8 bg-white">
        <Bee.Field label="Automation type">
          <Bee.ComboboxInput
            options={solutionOptions}
            nullable={false}
            onChange={([value, _]) => setSolutionId(value as number)}
            value={solutionId}
          />
        </Bee.Field>

        {currentSolution?.setupMode === "external" && (
          <RailsForm action={currentSolution.externalSetupPath} method="post">
            <Bee.Button type="submit">Set up your integration</Bee.Button>
          </RailsForm>
        )}
      </div>

      {currentSolution?.setupMode === "internal" && (
        <div className="flex flex-col gap-12 mx-6 my-2 p-8 bg-white">
          <CustomTraySolutionForm
            redirectOnSuccess={redirectOnSuccess}
            solutionForm={currentSolution.form}
            solutionId={solutionId}
            storeId={storeId}
          />
        </div>
      )}
    </>
  )
}
